import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { Dictionary } from 'vue-router/types/router';
import { Form as ElForm, Input } from 'element-ui';
import LanguageSelect from '@/components/language-select/language-select.vue';
import Verify from './verifition/verify.vue';
import ResetPassword from '../reset-password/reset-password.vue';
import { UserModule } from '@/store/modules/user';
import MiniResetPassword from '../reset-password/mini-reset-password/mini-reset-password.vue';
import { encryptAes } from '@/utils/cryption';
@Component({
  name: 'login',
  components: {
    LanguageSelect,
    Verify,
    ResetPassword,
    MiniResetPassword
  }
})
export default class Login extends Vue {
  public loginForm = {
    username: '',
    password: '',
    memberPassword: false
  };

  public loginRules = {
    username: [
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(this.$t('login.emptyUsername') as string));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    password: [
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(this.$t('login.emptyPassword') as string));
          } else {
            callback();
          }
        },
        trigger: 'blur'
      }
    ]
  };

  public loading = false;

  private redirect?: string;

  private otherQuery: Dictionary<string> = {};

  private dialogVisible = false;

  public constructor() {
    super();
  }

  public mounted(): void {
    if (this.loginForm.username === '') {
      (this.$refs.username as Input).focus();
    } else if (this.loginForm.password === '') {
      (this.$refs.password as Input).focus();
    }
  }

  @Watch('$route', { immediate: true })
  public onRouteChange(route: Route): void {
    // TODO: remove the "as Dictionary<string>" hack after v4 release for vue-router
    // See https://github.com/vuejs/vue-router/pull/2050 for details
    const query = route.query as Dictionary<string>;
    if (query) {
      this.redirect = query.redirect;
      this.otherQuery = this.getOtherQuery(query);
    }
  }

  public handleLogin(): void {
    (this.$refs.loginForm as ElForm).validate((valid: boolean) => {
      if (!valid) {
        return false;
      }
      UserModule.setAccount(this.loginForm.username);
      this.useVerify();
    });
  }

  /**
   * 调起滑动验证
   */
  public useVerify(): void {
    (this.$refs.verify as any).show();
  }

  /**
   * 验证成功的回调
   */
  public async success(checkRes: { loginCode: string }): Promise<void> {
    this.loading = true;
    const passwordEncrypted = encryptAes(`${this.loginForm.password}@-login-@${checkRes.loginCode}`);
    UserModule.Login({ username: this.loginForm.username, password: passwordEncrypted })
      .then(() => {
        this.$router.push({
          path: this.redirect || '/',
          query: this.otherQuery
        });
      })
      .catch(() => {})
      .finally(() => {
        this.loading = false;
      });
  }

  /**
   * 忘记密码
   */
  public forgetPassword(): void {
    this.dialogVisible = true;
  }

  private getOtherQuery(query: Dictionary<string>): Dictionary<string> {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== 'redirect') {
        acc[cur] = query[cur];
      }
      return acc;
    }, {} as Dictionary<string>);
  }
}

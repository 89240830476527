import { render, staticRenderFns } from "./reset-password.vue?vue&type=template&id=bf7b08de&"
import script from "./reset-password.ts?vue&type=script&lang=ts&"
export * from "./reset-password.ts?vue&type=script&lang=ts&"
import style0 from "./reset-password.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import { authService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { messageError } from '@/utils';
import { encryptAes } from '@/utils/cryption';
import { emailReg, passwordReg } from '@/utils/validate';
import { Form, Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class ResetPassword extends mixins(DialogMixin) {
  public verifyDescribe = '';
  public intervalTime = 60;
  public canSend = true;
  public sendVerifyCodeloading = false;
  public resetPasswordForm = {
    email: '',
    verifyCode: '',
    password: '',
    checkPassword: ''
  };
  public resetPasswordRules = {
    email: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(this.$t('resetPassword.emailEmpty') as string));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (!emailReg(value)) {
            callback(new Error(this.$t('resetPassword.emailError') as string));
            return;
          }
          callback();
        },
        trigger: ['blur', 'change']
      }
    ],
    verifyCode: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(this.$t('resetPassword.verifyCodeEmpty') as string));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    password: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(this.$t('resetPassword.passwordEmpty') as string));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (!passwordReg(value)) {
            callback(new Error(this.$t('resetPassword.passwordError') as string));
            return;
          }
          callback();
        },
        trigger: ['blur', 'change']
      }
    ],
    checkPassword: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(this.$t('resetPassword.checkPasswordEmpty') as string));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (!passwordReg(value)) {
            callback(new Error(this.$t('resetPassword.passwordError') as string));
            return;
          }
          callback();
        },
        trigger: ['blur', 'change']
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (value !== this.resetPasswordForm.password) {
            callback(this.$t('resetPassword.passwordNotEqual'));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  /**
   * 发送邮件验证码
   */
  public sendEmailVerifyCode(): void {
    this.sendVerifyCodeloading = true;
    (this.$refs.resetPasswordForm as ElForm).validateField('email', async res => {
      if (res) {
        this.sendVerifyCodeloading = false;
        return;
      }
      try {
        await authService.sendVerificationCode(this.resetPasswordForm.email);
        this.canSend = false;
        this.verifyDescribe = this.intervalTime + 's';
        const clock = window.setInterval(() => {
          this.intervalTime--;
          this.verifyDescribe = this.intervalTime + 's';
          if (this.intervalTime < 0) {
            window.clearInterval(clock);
            this.verifyDescribe = '';
            this.intervalTime = 60;
            this.canSend = true;
          }
        }, 1000);
      } catch (error) {
        messageError(error);
      } finally {
        this.sendVerifyCodeloading = false;
      }
    });
  }

  /**
   * 	Dialog 关闭动画结束时的回调
   */
  public dialogClosed(): void {
    (this.$refs.resetPasswordForm as Form).resetFields();
  }

  public async onOkClick(): Promise<void> {
    this.submitLoading = true;
    (this.$refs.resetPasswordForm as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        this.submitLoading = false;
        return false;
      }
      try {
        const request = {
          email: this.resetPasswordForm.email,
          newPassword: encryptAes(this.resetPasswordForm.password),
          verificationCode: this.resetPasswordForm.verifyCode
        };
        await authService.forgetPassword(request);
        Message.success(this.$t('resetPassword.resetSuccess') as string);
        this.syncedVisible = false;
      } catch (error) {
        messageError(error);
      } finally {
        this.submitLoading = false;
      }
    });
  }

  public get getLabelWidth(): string {
    if (this.$i18n.locale === 'zh-cn') {
      return '80px';
    }
    return '140px';
  }
}
